import React from 'react';
import SlickSlider from '../UI/Carousel/slick'
import SliderData from '../../data/Slider/home-one'
import {Link} from "react-router-dom";


    const NextArrow = ({className, onClick}) => {
        return (
            <button className={className} onClick={onClick}><i className="arrow_right"/></button>
        )
    };

    const PrevArrow = ({className, onClick}) => {
        return (
            <button className={className} onClick={onClick}><i className="arrow_left"/></button>
        )
    };

    const Slider = () => {

        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: true,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
            nextArrow: <NextArrow/>,
            prevArrow: <PrevArrow/>,
            responsive: [
                {
                    breakpoint: 1229,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                }
            ]
        };

        return (
            <div className="slides_wrapper">
                <div className="slider_home">
                    <SlickSlider settings={settings}>
                        {
                            SliderData.map(item => (
                                <div className={`slider-${item.id}`} kEY={item.id}>
                                    <div className="single_slider"
                                        style={{backgroundImage: `url(${require('../../assets/images/' + item.bg)})`}} >
                                        <div className="slider_item_tb">
                                            <div className="slider_item_tbcell">
                                                <div className="container">



                                                    <div className="row" style={{flex: 1, flexDirection: 'row'}}>
                                                        <div className="col-lg-6 col-sm-12">
                                                            <h3 style={{fontSize: "39px"}} dangerouslySetInnerHTML={{__html: item.title}}/>
                                                            <p dangerouslySetInnerHTML={{__html: item.description}}/>
                                                            <div className="slider_btn">
                                                                <Link to={`${process.env.PUBLIC_URL + item.btnLink}`} className="slider_btn_one">{item.btnText}</Link>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </SlickSlider>
                </div>
            </div>
        );
    };

export default Slider;