import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home";
import Service from "./pages/Service";
import SingleService from "./pages/SingleService";
import Companies from "./pages/Companies";
import Contact from "./pages/Contact";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import ECOTEC from "./pages/ECOTEC";
import Nbmpage from "./pages/NBM";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import clientLogin from "./pages/ClientLogin";
import ClientRegistration from "./pages/ClientRegistration";
import ClientDashboard from "./pages/ClientDashboard";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-two"}`}
            component={HomeTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-three"}`}
            component={HomeThree}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/service"}`}
            component={Service}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service/:serviceIDD"}`}
            component={SingleService}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/actandrules"}`}
            component={Companies}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/clientLogin"}`}
            component={clientLogin}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/clientRegistration"}`}
            component={ClientRegistration}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/clientDashboard"}`}
            component={ClientDashboard}
          />

          <Route exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
