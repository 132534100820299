import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import PageHeader from '../components/PageHeader'
import About from '../components/About/home-one'
import Skill from '../components/Skill';
import Services from '../components/Services/home-one'


const Aboutpage = () => {

    return(
        <Fragment>
            <MetaTags>
              <title>Ecotec Associates | About</title>
              <meta
                name="description"
                content="ECOTEC Associates."
              />
            </MetaTags>

            <LayoutOne>

                <PageHeader
                    bgImg={require('../assets/images/page_bg.jpg')}
                    title="About Us"
                />

                <About />
                <Services />
                <Skill />

            </LayoutOne>
        </Fragment>

    );
};

export default Aboutpage;