import React from 'react';
import {Link} from "react-router-dom";


function DowndoalArea() {
    return (
        <div className="download">
            <marquee direction="up" truespeed="" scrolldelay="65" scrollamount="1"
                     behavior="scroll" height="149px" align="middle" onmouseover="this.stop()" onmouseout="this.start()">
                {/* <ul>
                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/Bangladesh_Labor_Act_2006.pdf')} >Bangladesh_Labor_Act_2006</a></li>
                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/Bangladesh_Labor_Rules_2015.pdf')} >Bangladesh Labor Rules 2015</a></li>
                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/Environmental_Conservation_Act_1995.pdf')} >Environmental Conservation Act 1995</a></li>
                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/Environmental_Conservation_Rules_1997.pdf')} >Environmental Conservation Rules 1997</a></li>
                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/EY_Company_Profile.pdf')} >ECOTEC Company Profile</a></li>
                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/Minimun_Wages_Gazzette_for_RMG_2013.pdf')} >Minimum Wages Gazzette for RMG 2013</a></li>

                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/Profile_EY_Business_Solution.pdf')} >Profile ECOTEC Associates</a></li>
                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/Profile_Elliot_&_Young.pdf')} >Profile ECOTEC Associates</a></li>
                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/Profile_EY_Asset_Management_download.pdf')} >Profile ECOTEC Asses Management</a></li>
                    <li><a className="footer-download-link-bdcolor" download href={require('../../assets/download/Profile_NBM_Trade_International.pdf')} >Profile NBM Trade International</a></li>

                </ul> */}

            </marquee>

        </div>

    );
}

export default DowndoalArea;