import React from 'react';

function UseFullLinkArea() {
    return (
        <div className="download">
            <marquee direction="up" truespeed="" scrolldelay="65" scrollamount="1"
                     behavior="scroll" height="149px" align="middle">
                <ul>
                    <li><a className="footer-download-link-bdcolor" href="hhttps://nbr.gov.bd/regulations/acts/finance-acts/eng" target="_blank"> Finance Act</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://nbr.gov.bd/regulations/acts/income-tax-acts/eng" target="_blank"> Income Tax Act</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://nbr.gov.bd/form/income-tax/eng" target="_blank"> Income Tax Form</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://nbr.gov.bd/regulations/acts/vat-acts/eng" target="_blank"> VAT Act</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://nbr.gov.bd/regulations/sros/income-tax-sros/eng" target="_blank"> Income Tax SRO’s</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://roc.portal.gov.bd/sites/default/files/files/roc.portal.gov.bd/files/433ee068_3637_4b52_82ab_932a8589cd95/2020-09-16-12-38-b2d4d4493e8b0dbcebc3e108b336ab2f.pdf" target="_blank"> Company Act 1994</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://roc.portal.gov.bd/sites/default/files/files/roc.portal.gov.bd/files/8d5e4ade_dab2_4925_baa5_0319ff234743/Partnership%20Act,%201932.pdf" target="_blank"> The Partnership Act 1932</a></li>
                    <li><a className="footer-download-link-bdcolor" href="http://www.roc.gov.bd/site/page/0c190447-5b02-4054-8a5f-9161504f0b36/-" target="_blank"> Company Registration Fees</a></li>
                    <li><a className="footer-download-link-bdcolor" href="http://www.doe.gov.bd/site/page/40711011-9fa2-45f1-bfee-512c27103284/-" target="_blank"> Environmental Act & Rules</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://ecc.doe.gov.bd/users/?cmd=register" target="_blank"> ECC Registration</a></li>

                </ul>
            </marquee>
        </div>
    );
}

export default UseFullLinkArea;