import React from "react";
import SectionTitle from "../SectionTitles/SectionTitle";
import { Link } from "react-router-dom";

const AboutContent = (props) => {
  const serviceURL = `/about`;
  return (
    <section className="about-section" id="about">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 about_bottom_warp">
            <div className="about_list">
              {/* Start: Heading */}
              <SectionTitle title=" WELCOME TO ECOTEC ASSOCIATES" />
              <p>
                ECOTEC ASSOCIATES is a leading TAX, VAT and Legal issues
                consultancy organization in Bangladesh established on 2017 by
                some experienced & talented Processionals. ECOTEC ASSOCIATES
                brings together extensive capabilities to each clients, not just
                through its talented and experienced professionals but also as a
                strategic partner to our clients. We are there with our clients,
                drawing across Divisional lines, bringing value-added assistance
                to strategize and structure the most feasible functional,
                cost-effective and successful projects.
              </p>
              <p>
                Our culture is one of quality where we strive to be proud of
                every service we provide.
              </p>
              <p>
                Now a days outsourcing is a most popular issue in business
                sector, it has to way benefits. One is you will get quality
                service from professional firm and second benefit is reducing
                cost. Small business enterprise cannot recruit fulltime
                professional staff because of high cost but there is an
                opportunity to outsource the service from professional VAT, TAX,
                Business Law Accounting Service provider consultant. We working
                as an outsourcing personal for professional business development
                consultancy.
              </p>
              <p>
                ECOTEC ASSOCIATES can uniquely be a total service provider to
                our clients as a one-stop consultancy or standalone provider of
                specialized consulting services in followings area:
              </p>

              <li>Income TAX:</li>
              <li>Value Added Tax (VAT):</li>
              <li>Company Law & Corporate Affairs</li>
              <li>Accounting Service</li>
              <li>Accounting Software</li>
              <li>License and Certificate</li>

            </div>
          </div>
        </div>

        <div className="col-md-9 col-sm-12 about_bottom_warp"></div>

        {/* row */}
      </div>
      {/* container */}
    </section>
  );
};

export default AboutContent;
