import React, { useState, useRef } from 'react';
import ContactInfo from './ContactInfo';
import Form from './Form';
import SectionTitle from '../SectionTitles/SectionTitle';
import { matchPath } from 'react-router-dom';

const ClientRegistrationForm = () => {
  const [formValidationMessages, setFormValidationMessages] = useState([]);

  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const mobileNumberInputRef = useRef();
  const passwordInputRef = useRef();

  let url = `${process.env.PUBLIC_URL + '/clientLogin'}`;

  const clientRegistrationFormHandeler = (event) => {
    event.preventDefault();

    const enteredName = nameInputRef.current.value;
    const enteredEmail = emailInputRef.current.value;
    const enteredMobileNumber = mobileNumberInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    let errorMessages = [];

    if (enteredName.trim().length === 0) {
      const errMessage = 'Please enter Name';
      errorMessages = [...errorMessages, errMessage];
    }
    if (enteredEmail.trim().length === 0) {
      const errMessage = 'Please enter Email';
      errorMessages = [...errorMessages, errMessage];
    }
    if (enteredMobileNumber.trim().length !== 11) {
      const errMessage = 'Mobile number must have 11 digits';
      errorMessages = [...errorMessages, errMessage];
    }
    if (enteredPassword.trim().length < 5) {
      const errMessage = 'Password must be at least 6 character';
      errorMessages = [...errorMessages, errMessage];
    }

    if (errorMessages.length > 0) {
      setFormValidationMessages(errorMessages);
      return;
    }

    //Server side logic here
    console.log(`User Registration Successfull, 
                  Name: ${enteredName}
                  Email: ${enteredEmail}
                  Mobile Number: ${enteredMobileNumber}
                  PassWord: ${enteredPassword}`);

    nameInputRef.current.value = '';
    emailInputRef.current.value = '';
    mobileNumberInputRef.current.value = '';
    passwordInputRef.current.value = '';

    setFormValidationMessages([]);
  };

  return (
    <div className='global-container'>
      <div className='card login-form'>
        <div className='card-body'>
          <h3 className='card-title text-center'>Registration</h3>
          {
            /** showing error or validation message if any */
            formValidationMessages.length > 0 && (
              <div className='alert alert-danger'>
                <ul>{formValidationMessages.map((validationMessage)=>
                  <li key={Math.random().toString()}>{validationMessage}</li>
                )}</ul>
              </div>
            )
          }
          <form onSubmit={clientRegistrationFormHandeler}>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Name</label>
              <input
                type='text'
                className='form-control form-control-sm'
                id='exampleInputName'
                ref={nameInputRef}
                aria-describedby='nameHelp'
              />
            </div>

            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Email address</label>
              <input
                type='email'
                className='form-control form-control-sm'
                id='exampleInputEmail1'
                ref={emailInputRef}
                aria-describedby='emailHelp'
              />
            </div>

            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Mobile</label>
              <input
                type='text'
                className='form-control form-control-sm'
                id='exampleInputMobile'
                ref={mobileNumberInputRef}
                aria-describedby='mobileHelp'
              />
            </div>

            <div className='form-group'>
              <label htmlFor='exampleInputPassword1'>Password</label>
              <input
                type='password'
                className='form-control form-control-sm'
                id='exampleInputPassword1'
                ref={passwordInputRef}
              />
            </div>
            <button type='submit' className='btn btn-primary btn-block'>
              Sign in
            </button>
            <div className='sign-up'>
              Login here <a href={url}>Login</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClientRegistrationForm;
