import React from 'react';
import NavbarItem from './NavbarItem' 
import MobileMenu from "../../mobile-menu/MobileMenu";
import MobileBtn from "../../mobile-menu/MobileBtn";
import {Link} from 'react-router-dom';


//Logo 
// import logo from '../../../assets/images/logo.png'
import logo from '../../../assets/images/ea.png'
import SectionTitle from "../../SectionTitles/SectionTitle";



function Navbar(props) {

 

    return (
<div className="menu_area">	 
    <div style={{backgroundColor: "darkred"}} className="navigation">
        <div className="container"> 
            <div className="row">
                <div className="col-sm-12">
                
                    {/* Logo */}
                    <div className="header_logo" style={{marginTop:"-15px"}}>
                        <Link to={process.env.PUBLIC_URL + "/"}>
                            <img style={{width:'200px', paddingTop:'17px', marginTop:'8px', height:'75px', borderRadius:'5px'}} src={logo} alt="" />
                        </Link>

                        {/* <Link to={process.env.PUBLIC_URL + "/"}><h3 style={{display: "inline-block"}} ></h3></Link> */}

                    </div>

                
                {/* Mobile Menu */}

                <MobileBtn /> 

                <MobileMenu />

                {/* End:  Mobile Menu */}

                
                {/* Start:  NAVIGATION  */}
 
                <div id="navigation">
                    <ul>
                        <NavbarItem />
                    </ul>
                </div>

                {/* End: NAVIGATION  */}


                </div>
            </div>
        </div>
    </div>
</div>

    );
}

export default Navbar;