import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import PageHeader from '../components/PageHeader'
import Services from '../components/Services/home-one'
import Funfact from '../components/FunFact/home-one'
import Testimonial from '../components/Testimonials/home-one';
import LogoContainer from '../components/BrandLogo';
import ServiceDetails from "../templates/ServiceDetails";
import ServiceList from "../components/Services/home-one/ServiceIDetails";

const servicePage = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>Ecotec Associates | Home</title>
		  <meta
			name="description"
			content="ECOTEC Associates."
		  />
		</MetaTags>
		<LayoutOne>
		<div className="service-page">
			<PageHeader
				bgImg={require('../assets/images/page_bg.jpg')}
				title="Our Services"
			/>
			{/* <ServiceList /> */}
			<Services />
			<Funfact classes="funfact_serv text-center" />
		</div>
		</LayoutOne>
	  </Fragment>
	);
  };

  export default servicePage;



