import React from 'react';    
import SectionTitle from "../SectionTitles/SectionTitle";
import ProgressBar from 'react-bootstrap/ProgressBar'

const SkillSec = () => {
    return (
    <section className="our-skill section">
        <div className="container">
            {/* Heading */} 
            <SectionTitle 
                title="Our Skill" 
            />
            {/* End: Heading */ }
            <div className="row">
                <div className="col-lg-6 col-sm-12 col-xs-12">
                    <div className="skill-text">
                        <h2>How we work</h2>
                        <p>Collaboration is the kEY to success for sustainable development in the organization. We work collaboratively to achieve a shared goal. Our purpose is Building a sustainable working world. We go bEYond audit, inspection, and consultations; we are a Total solution on Sustainable Compliance to industries worldwide. Below characteristic made us different than others:</p>
                    </div>
                    <div className="about_pg_lst">
                        <ul>
                            <li>
                                <i className="fa fa-check"></i> Competent Audit Team
                            </li>
                            <li>
                                <i className="fa fa-check"></i> Highly Experienced Management Consultants
                            </li>
                            <li>
                                <i className="fa fa-check"></i> Professional Environmental Experts
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <i className="fa fa-check"></i> Highly Skilled Trainers:
                            </li>
                            <li>
                                <i className="fa fa-check"></i> Legal Advisors:
                            </li>
                            <li>
                                <i className="fa fa-check"></i> 24/7- Technical Support:
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="skill_wrap col-lg-6 col-sm-12 col-xs-12">
 
                    {/*  Single Skill */}
                    <div className="single-skill">
                        <div className="skill-info">
                            <h4>Finance</h4>
                        </div> 
                        <ProgressBar animated variant="success" now={70} /> 
                    </div>
                    {/*  End Single Skill */}
                    {/*  Single Skill */}
                    <div className="single-skill">
                        <div className="skill-info">
                            <h4>Marketings</h4>
                        </div> 
                        <ProgressBar animated variant="success" now={90} /> 
                    </div>
                    {/*  End Single Skill */}
                    {/*  Single Skill */}
                    <div className="single-skill">
                        <div className="skill-info">
                            <h4>Resource</h4>
                        </div> 
                        <ProgressBar animated variant="success" now={60} /> 
                    </div>
                    {/*  End Single Skill */}
                    {/*  Single Skill */}
                    <div className="single-skill">
                        <div className="skill-info">
                            <h4>Development</h4>
                        </div>
                        <ProgressBar animated variant="success" now={80} /> 
                    </div>
                    {/*  End Single Skill */}
                </div>
            </div>
        </div>
    </section>
    );
};

export default SkillSec;