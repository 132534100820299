import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import PageHeader from '../components/PageHeader'
import SectionTitle from "../components/SectionTitles/SectionTitle";
import TeamList from  '../components/Team/home-one/TeamList'
import companyData from '../data/Companies/company'



const TeamPage = ( ) => {

    return(
        <Fragment>
            <MetaTags>
              <title>Ecotec Associates | About</title>
              <meta
                name="description"
                content="Ecotec Associates"
              />
            </MetaTags>

            <LayoutOne>

                <PageHeader
                    bgImg={require('../assets/images/page_bg.jpg')}
                    title="Our Companies"
                />

                <section className={`team_section team_page`}>
                    <div className="container">
                        {/* Heading */}
                        <SectionTitle
                            // title="Meet the team"
                        />
                        {/* End: Heading */ }

                        <div className="row">

                            {
                                companyData.slice(0,8).map(team=>(
                                    <TeamList
                                        kEY={team.id}
                                        img={team.img}
                                        name={team.name}
                                        pageUrl={team.pageUrl}
                                        linkedinUrl={team.linkedinUrl}
                                    />
                                ))
                            }

                        </div>

                    </div>
                </section>

            </LayoutOne>
        </Fragment>

    );
};

export default TeamPage;