import React from "react";
import SectionTitle from "../../SectionTitles/SectionTitle";
import MVVImage from "../MVVImage";

const AboutContent = () => {
  return (
    <section className="about-section" id="about">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 about_bottom_warp">
            <div className="about_list">
              {/* Start: Heading */}

            <MVVImage />

              <h3>Who we are..</h3>
              <p>
                ECOTEC ASSOCIATES is a renowned Tax, Vat, Accounts and business
                law consultancy firm in Dhaka, Bangladesh. We are servicing
                effective and skied consultancy services connection with the
                Companies Act 1994, Trade Organization Act, Societies
                Registration Act, Trust Act, Income Tax Ordinance, Sales Tax,
                Gift Tax, Customs Act, Value Added Tax Act, Estate Duty Act,
                Patent-Design and Trademark Registration Act, Copyright Act,
                Bangladesh Accounting Standard (BSA), Bangladesh Costing
                Standard, International Accounting Standard (IAS) and any other
                Acts which are prevailing and operative in Bangladesh. We
                provide Income Tax Consultancy, Value Added Tax (VAT),
                Accounting Services, Company Law & Corporate Affairs, Bangladesh
                Investment Development Authority Affairs (BIDA), ERC, IRC,
                Project Profile and local & international Accountings
                Outsourcing and any other services as the company as well as
                individual may think fit and proper. We are committed to provide
                the best services to our clients."
              </p>

              <h3>Code of Ethics And Compliance</h3>
              <li>
                {" "}
                We provide our service with our client integrity, competence and
                objectivity.
              </li>
              <li>
                {" "}
                We will keep confidential all client’s information and records.
              </li>
              <li>
                {" "}
                We will accepts which engagements for which we are qualified by
                our experience and competence.
              </li>
              <li>
                {" "}
                We will assign staff to an individual engagements according with
                their experience, knowledge and expertise.
              </li>
              <li>
                {" "}
                We will agree independently and in advance on the basis for our
                fees and expenses that are reasonable, legitimate, and
                commensurate with the services we deliver and the responsibility
                we accept.
              </li>
              <li>
                {" "}
                We will disclose to our clients in advance if any fees or
                commissions need for services which recommend by our clients.
              </li>
              <li>
                {" "}
                We will respect the intellectual property rights of our clients,
                other consulting firms, and sole practitioners and will not use
                proprietary information or methodologies without permission.
              </li>

              <h3>Mission:</h3>
              <p>
                Our mission is to provide the best professional service to
                client to fulfill their desired outcome. Our highly professional
                lawyers with a deep understanding of their profession committed
                to take any kind of challenging situation. We are an ethically
                focused group of lawyers where creating solution for achieving
                the desired outcome for our clients takes priority. We are not
                afraid to take creative routes and systemic innovation for
                accomplishing our goals. We never compromise in any kind of
                situation and we are committed to excellence as it is our sole
                mission to provide the best professional solution to our
                honorable clients.
              </p>

              <h3>Vision: </h3>
              <p>
                Our vision is to create a workplace where we put our client
                first. We are dedicated to provide wide range of services where
                each individual and corporate client needs are handled according
                to their requirements and expectations. We are vigorous in our
                attempt to provide cost-effective, timely and precise advice and
                route that will help our honorable clients to achieve their
                goals. We are highly passionate to gain our clients’ trust as we
                believe creating a mutually credible environment among our self
                and clients ultimately will guide us toward our mutual goals.
              </p>
            </div>
          </div>
        </div>
        {/* row */}
      </div>
      {/* container */}
    </section>
  );
};

export default AboutContent;
