import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import ClientLoginForm from "../components/ContactForm/ClientLoginForm";
import PageHeader from "../components/PageHeader";

const clientLogin = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Ecotec Associates | Contact U</title>
        <meta name="description" content="ECOTEC Associates." />
      </MetaTags>

      <LayoutOne>


        {/* <ContactForm /> */}

        <ClientLoginForm />

      </LayoutOne>
    </Fragment>
  );
};

export default clientLogin;
