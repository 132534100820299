import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Slider from '../components/Slider/SliderOne'
import About from '../components/About'
import Services from '../components/Services/home-one'
import WhyChooseUs from '../components/WhyChooseUs'
import Testimonial from '../components/Testimonials/home-one';
import LogoContainer from '../components/BrandLogo';
import ContactForm from '../components/ContactForm';


const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Ecotec Associates | Home</title>
        <meta
          name="description"
          content="Elliot Young"
        />
      </MetaTags>
      <LayoutOne>

        <Slider />
        <About />
        <Services />
        <WhyChooseUs />
        {/*<Testimonial />*/}
        {/*<LogoContainer />*/}
        <ContactForm />

      </LayoutOne>
    </Fragment>
  );
};

export default Home;
