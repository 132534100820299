import React from 'react';
import image from '../../assets/images/aboutus_big.png';

const MVVImage = () => {

    return (
        <div>
            <img style={{width:'100%', height:'450px'}} src={image} alt="BigCo Inc. logo"/>
        </div>
    );
};

export default MVVImage;
