import React from 'react';
import {Link} from "react-router-dom";
import navbarData from "../../../data/Navbar/navbar";

function NavbarItem(props) {


    let urls = [];

    urls[1]= "https://nbr.gov.bd/regulations/acts/finance-acts/eng";
    urls[2]= "https://nbr.gov.bd/regulations/acts/income-tax-acts/eng";
    urls[3]= "https://nbr.gov.bd/form/income-tax/eng";
    urls[4]= "https://nbr.gov.bd/regulations/acts/vat-acts/eng";
    urls[5]= "https://nbr.gov.bd/regulations/sros/income-tax-sros/eng";
    urls[6]= "https://roc.portal.gov.bd/sites/default/files/files/roc.portal.gov.bd/files/433ee068_3637_4b52_82ab_932a8589cd95/2020-09-16-12-38-b2d4d4493e8b0dbcebc3e108b336ab2f.pdf";
    urls[7]= "https://roc.portal.gov.bd/sites/default/files/files/roc.portal.gov.bd/files/8d5e4ade_dab2_4925_baa5_0319ff234743/Partnership%20Act,%201932.pdf";
    urls[8]= "http://www.roc.gov.bd/site/page/0c190447-5b02-4054-8a5f-9161504f0b36/-";
    urls[9]= "http://www.doe.gov.bd/site/page/40711011-9fa2-45f1-bfee-512c27103284/-";
    urls[10]= "https://ecc.doe.gov.bd/users/?cmd=register";

    return(
        navbarData.map(item=>(
            <li kEY={item.id}
                className={item.subMenu || item.megaMenu ? 'menuItemHasChildren' : ''}
            >
                <Link to={`${process.env.PUBLIC_URL + item.link}`}>{item.title}</Link>
                {(() => {
                    if(item.subMenu){
                        return(
                            <ul className="subMenu">
                                {
                                    item.subMenu.map((subItem,index)=>{

                                        if(item.id === 4){
                                            return <li kEY={index}><a target={'_blank'} href={urls[subItem.id]}>{subItem.title}</a></li>
                                        }else{
                                            return <li kEY={index}><Link to={`${process.env.PUBLIC_URL + subItem.link}`}>{subItem.title}</Link></li>
                                        }
                                    })
                                }
                            </ul>
                        )
                    }

                })()}
            </li>
        ))
    )
}

export default NavbarItem