import React from 'react';
import {Link} from "react-router-dom";

const PricingItem = (props) => {
    return (
        <div className="col-md-6 col-sm-12">
            <div className="member_warp">

				<div className="member_img">
					<img width={500} src={require('../../../assets/images/' + props.img)} alt="service" />
				</div>

                <div className="member_info">

                    <Link to={props.pageUrl}><h4><strong>{props.name}</strong></h4></Link>

                </div>

            </div>
        </div>
    );
};

export default PricingItem;