import React, { useState, useRef } from 'react';
import ContactInfo from './ContactInfo';
import Form from './Form';
import SectionTitle from '../SectionTitles/SectionTitle';

const ContactForm = () => {
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const [formValidationMessages, setFormValidationMessages] = useState([]);

  let url = `${process.env.PUBLIC_URL + '/clientRegistration'}`;
  //let dashboardUrl = `${process.env.PUBLIC_URL + '/clientDashboard'}`;

  const loginFormSubmitHandeler = (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    let errorMessages = [];

    if (enteredEmail.trim().length === 0) {
      const errMessage = 'Please enter Email';
      errorMessages = [...errorMessages, errMessage];
    }
    if (enteredPassword.trim().length < 5) {
      const errMessage = 'Password must be at least 6 character';
      errorMessages = [...errorMessages, errMessage];
    }

    if (errorMessages.length > 0) {
      setFormValidationMessages(errorMessages);
      return;
    }

    // Server Side logic here
    console.log(`User Login Successfull,     
                  Email: ${enteredEmail}    
                  PassWord: ${enteredPassword}`);

    emailInputRef.current.value = '';
    passwordInputRef.current.value = '';

    setFormValidationMessages([]);
  };

  return (
    <div className='global-container'>
      <div className='card login-form'>
        <div className='card-body'>
          <h3 className='card-title text-center'>Login</h3>

          {
            /** showing error or validation message if any */
            formValidationMessages.length > 0 && (
              <div className='alert alert-danger'>
                <ul>
                  {formValidationMessages.map((validationMessage) => (
                    <li key={Math.random().toString()}>{validationMessage}</li>
                  ))}
                </ul>
              </div>
            )
          }

          <form onSubmit={loginFormSubmitHandeler}>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Email address</label>
              <input
                type='email'
                className='form-control form-control-sm'
                id='exampleInputEmail1'
                ref={emailInputRef}
                aria-describedby='emailHelp'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputPassword1'>Password</label>
              <a href='#' style={{ float: 'right', fontSize: '12px' }}>
                Forgot password?
              </a>
              <input
                type='password'
                className='form-control form-control-sm'
                id='exampleInputPassword1'
                ref={passwordInputRef}
              />
            </div>
            <button type='submit' className='btn btn-primary btn-block'>
              Sign in
            </button>
            <div className='sign-up'>
              Don't have an account? <a href={url}>Create One</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
