import React from 'react';

const BDOffice = () => {

    return (
        <div className="row">
            <div className="bottom_contact col-sm-3 col-xs-12"><i className="icon_pin_alt"></i>
                <h5>Bangladesh Office</h5>
                <address>ECOTEC Associates House: 13, Road: 2, Sector 5 Uttara Model Town Dhaka-1230, Bangladesh</address>
            </div>
            <div className="bottom_contact col-sm-3 col-xs-12"><i className="icon_phone"></i>
                {/*<h5>Cell Number</h5>*/}
                <h6>+88 01939 434113</h6>
            </div>
            <div className="bottom_contact col-sm-3 col-xs-12"><i className="icon_chat"></i>
                {/*<h5>Email</h5>*/}
                <h6>info@ecotec-bd.com</h6>
            </div>
            <div className="bottom_contact col-sm-3 col-xs-12"><i className="icon_clock_alt"></i>
                {/*<h5>Opening Hours</h5>*/}
                <h6>Sat-Th 9am - 6pm</h6>
            </div>
        </div>
    );
}

export default BDOffice;