import React from 'react';
import {Link} from "react-router-dom";


function ServiceList() {

    return (

        <section className="service_section pt-2" id="agenda">
            <div className="container">

                <div className="row" id="service">

                    <div className="col-sm-4">
                        <h5>Audit &amp; Assurance</h5>
                        <ul>
                            <li> ECOTEC Social Compliance</li>
                            <li> ECOTEC C-TPAT</li>
                            <li> Buyers COC</li>
                            <li> SMETA</li>
                            <li> ISO-14001</li>
                            <li> ISO -9001</li>
                        </ul>

                        <h5>Compliance / Management Consultancy</h5>
                        <ul>
                            <li> BSCI / SMETA</li>
                            <li> SOCIAL COMPLIANCE</li>
                            <li> C-TPAT</li>
                            <li> FIRE SAFETY</li>
                            <li> ELECTRICAL SAFETY</li>
                            <li> BUILDING SAFETY</li>
                            <li> HEALTH &amp; SAFETY CONDITION</li>
                            <li> ENVIRONMENTAL SUSTAINABILITY</li>
                            <li> RCC/NIRAPON</li>
                            <li> OCS</li>
                            <li> GOTS</li>
                            <li> BCI</li>
                            <li> DETOX</li>
                            <li> HIGG INDEX</li>
                            <li> LEED CERTIFICATION</li>
                            <li> NEW PROJECT</li>
                            <li> FINANCE ARRANGEMETN ON NEW PROJECT</li>
                            <li> CHILD LABOR REHABILITATION</li>
                            <li> FEMALE EMPOWERMENT</li>
                            <li> PROJECTS</li>
                        </ul>

                        <h5>SEFS (Structural, Electrical and Fire Safety)</h5>


                        <b>Electrical Safety:</b>
                        <ul>
                            <li>Electrical Safety Consultancy</li>
                            <li>New Factory / Project Consultancy</li>
                            <li>Electrical Installation &amp; Electrical Drawing (SLD)</li>
                            <li>LPS Design &amp; Drawing</li>
                            <li>LPS Supply &amp; Installation</li>
                            <li>LED Tube Light Supply</li>
                            <li>Circuit Breaker Supply</li>
                            <li>Logout Tag-out Supply</li>
                            <li>MDB/ DB / SDB Board Supply</li>
                            <li>Development Training</li>
                        </ul>

                        <b>Structural Safety:</b>
                        <ul>
                            <li>Architectural Design</li>
                            <li>Structural Design</li>
                            <li>Retrofitting</li>
                            <li>Supervising the construction projects</li>
                            <li>Project Management</li>
                            <li>Development Training</li>
                        </ul>

                        <b>Fire Safety:</b>
                        <ul>
                            <li>Fire Safety Consultancy</li>
                            <li>Fire Detection and Protection Design and Drawing</li>
                            <li>Fire Detection and Protection Installation (fire Alarm, Hire Hydrant, Fire Sprinkler)
                            </li>
                            <li>Fire Door Supply</li>
                            <li>Development Training</li>
                        </ul>


                    </div>


                    <div className="col-sm-4">
                        <h5>Environmental Sustainability:</h5>
                        <ul>
                            <li> HIGG INDEX</li>
                            <li> DETOX</li>
                            <li> ZDHC</li>
                            <li> LEED</li>
                            <li> Environmental Testing &amp; Monitoring (Air / Stack / Humidity / Noise / Light /
                                Temperature)
                            </li>
                            <li> Environmental Impact Assessments (EIA)</li>
                            <li> Environmental Management Plan (EMP)</li>
                            <li> Green House Gas (GHG)</li>
                            <li> Ozone Depletion Substance (ODS)</li>
                            <li> Audio Metric Monitoring/Testing</li>
                            <li> Environmental Management Systems (ISO 14001)</li>
                            <li> Water Quality Assessment and Monitoring</li>
                            <li> Groundwater Modeling, Monitoring and Aquifer Testing</li>
                            <li> Water and Wastewater Systems</li>
                            <li> Environmental and Quality Management Systems</li>
                            <li> ISO 9000/14000/22000 implementation, consulting and Certification.</li>
                            <li> Environmental Safety, Disaster Control, and Emergency Preparedness Plans</li>
                            <li> Remedial Action Plans and Designs</li>
                            <li> Risk Assessments &amp; Reduction</li>
                            <li> Contamination Assessments</li>
                            <li> Environmental/Energy Audits</li>
                            <li> Water Reuse</li>
                            <li> Industrial Water Use/Balance</li>
                            <li> Industrial Wastewater Treatment and Disposal</li>
                            <li> Effluent Disposal System Design</li>
                            <li> Clean Technology Applications</li>
                            <li> Industrial Air Pollution Control</li>
                            <li> Solid Waste Management</li>
                            <li> Liquid Waste Management</li>
                            <li> Hazardous Materials and Waste Management</li>
                            <li> Medical and Health Waste Management</li>
                            <li> Renewable Energy Systems Design and Development</li>
                            <li> Energy Saving and Optimization</li>
                            <li> Energy Audits</li>
                            <li> Chemical Management</li>
                            <li> All type of equipment/meters for Environmental Monitoring and Control system</li>
                        </ul>


                        <h5>ECOTEC Software and Solutions:</h5>
                        <ul>
                            <li>Mobile App Development</li>
                            <li>Web App Development</li>
                            <li>Business Intelligence</li>
                            <li>Custom ERP</li>
                            <li>Custom Software Development</li>
                            <li>RMG Software Solutions</li>
                        </ul>




                    </div>



                    <div className="col-sm-4">
                        <h5>Training Academy:</h5>
                        <ul>
                            <li> Freedom of Association (FOA) and Collective Bargaining</li>
                            <li> Formation of Trade Union (TU)</li>
                            <li> Non physical Abuse and Corporal Punishment (Decent Behaviour)</li>
                            <li> Harassment and Abuse</li>
                            <li> Non-discrimination</li>
                            <li> Workplace Safety</li>
                            <li> Rights of Female workers</li>
                            <li> Woman Health</li>
                            <li> Child Labor &amp; Rehabilitation and Adolescent worker &amp; Working Condition</li>
                            <li> Compensation and Benefits</li>
                            <li> Working Hours</li>
                            <li> Social Compliance</li>
                            <li> C-TPAT (Customers Trade Partnership Against Terrorism)</li>
                            <li> Hazard Identification and Risk Assessment (HIRA)</li>
                            <li> Fire Safety, Electrical Safety and Building Safety</li>
                            <li> Environmental Sustainability</li>
                            <li> Higg Index</li>
                            <li> Detox</li>
                            <li> ZDHC</li>
                            <li> LEED Certification</li>
                        </ul>

                        <h5>Income Tax and Accounts:</h5>
                        <ul>
                            <li> Individual Tax Assessment</li>
                            <li> Company Tax Assessment</li>
                            <li> Value Added Tax</li>
                            <li> Financial Statement Preparation</li>
                            <li> Legal Support on Company Laws</li>
                            <li> Legal support on Company Formation</li>
                        </ul>

                        <h5>Projects Management:</h5>
                        <ul>
                            <li> Child Labor Rehabilitation</li>
                            <li> Schooling project on varies location, such as Rohinga Projects</li>
                            <li> Training to workers on behalf of clients</li>
                            <li> Any project implementation on behalf of clients</li>
                            <li> Providing resources based on client needs</li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceList;

