import React from 'react';
import CanadaOffice from './CanadaOffice'
import BDOffice from './BDOffice'

const COntactInfo = () => {

    return (

    <div>
        <CanadaOffice/>
        <BDOffice/>
    </div>

    );
}

export default COntactInfo;